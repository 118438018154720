import { useEffect } from "react";
import { selectGameIndex, selectGameStatus } from "../../app/gameSlice";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  fetchPuzzleStats,
  selectPuzzleStats,
} from "../../app/puzzleStatsSlice";
import { useMessage } from "../../hooks/useMessage";
import { logInfo } from "../../helpers/tracing";

const StatsRank = () => {
  logInfo({ message: "Rendering StatsRank" });

  const gameIndex = useAppSelector(selectGameIndex);
  const gameStatus = useAppSelector(selectGameStatus);
  const dispatch = useAppDispatch();
  const stats = useAppSelector(selectPuzzleStats);

  const needsNewStats = stats.gameIndex !== gameIndex;

  const displayPercentile = stats.userPercentile
    ? (100 - stats.userPercentile).toFixed(0)
    : "";

  const rankTextTop = useMessage("rank_text.top");
  const rankTextPercentile = useMessage("rank_text.percentile", {
    percentile: displayPercentile,
  });

  let rankText = "";

  if (stats.userPercentile !== undefined && stats.userPercentile >= 50) {
    if (stats.userPercentile === 100) {
      rankText = rankTextTop.body;
    } else {
      rankText = rankTextPercentile.body;
    }
  }

  let solvedRate: string | undefined = undefined;

  if (stats.gameIndex !== undefined) {
    if (
      stats.solvedRate &&
      Math.round(stats.solvedRate) === 100 &&
      stats.solvedRate !== 100
    ) {
      solvedRate = "99";
    } else {
      solvedRate = (stats.solvedRate ?? 0).toFixed(0);
    }
  }

  useEffect(() => {
    if (needsNewStats) {
      dispatch(fetchPuzzleStats(gameIndex));
    }
  }, [gameIndex, gameStatus, needsNewStats, dispatch]);

  const solvedRateMessage = useMessage("stats.solved_rate", { solvedRate });
  const loadingMessage = useMessage("stats.loading");

  return (
    <div className="mt-2">
      <p>
        {stats.gameIndex !== undefined && (
          <>
            {gameIndex !== undefined && <>{solvedRateMessage.body}</>}
            {rankText && (
              <>
                <br />
                {rankText}
              </>
            )}
          </>
        )}
        {stats.gameIndex === undefined && loadingMessage.body}
      </p>
    </div>
  );
};

export default StatsRank;
