import { AppDispatch } from "../app/store";
import { track } from "../app/trackingSlice";

const handleExternalLink = (
  e: React.MouseEvent<HTMLAnchorElement>,
  dispatch: AppDispatch
) => {
  dispatch(
    track({
      eventName: "click_external_link",
      properties: {
        targetUrl: (e.target as HTMLAnchorElement).href,
      },
    })
  );
};

const useHandleExternalLink = (dispatch: AppDispatch) => {
  return (e: React.MouseEvent<HTMLAnchorElement>) =>
    handleExternalLink(e, dispatch);
};

export default useHandleExternalLink;
