import { selectDailyStats, selectWinPercentage } from "../../app/gameSlice";
import { useAppSelector } from "../../hooks";
import { useMessage } from "../../hooks/useMessage";
import { StatPanel } from "./StatPanel";

export const StatsSummary = () => {
  const stats = useAppSelector(selectDailyStats);
  const winPercentage = useAppSelector(selectWinPercentage);

  const played = useMessage("played_stat");
  const playStreak = useMessage("play_streak_stat");
  const wonStat = useMessage("won_stat");
  const longestPlayStreak = useMessage("longest_play_streak_stat");
  const statisticsHeading = useMessage("stats_screen_title");

  return (
    <div className="border border-slate-200 rounded-lg py-3 px-2 mt-3 mx-3">
      <h2 className="text-center font-bold mb-2 text-sm">
        {statisticsHeading.body}
      </h2>
      <div className="grid grid-cols-4 gap-4">
        <StatPanel
          size="small"
          label={played.body}
          value={stats?.playCount.toString()}
        />
        <StatPanel
          size="small"
          label={playStreak.body}
          value={stats?.playStreak.toString()}
        />
        <StatPanel
          size="small"
          label={wonStat.body}
          value={`${winPercentage}%`}
        />
        <StatPanel
          size="small"
          label={longestPlayStreak.body}
          value={stats?.maxPlayStreak.toString()}
        />
      </div>
    </div>
  );
};
