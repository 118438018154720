import { postProfile } from "../helpers/api";
import { startAppListening } from "./listenerMiddleware";
import {
  startUpdate,
  updateFailure,
  updateProfile,
  updateSuccess,
} from "./profileSlice";

export const registerProfileListener = () => {
  startAppListening({
    actionCreator: updateProfile,
    effect: async (action, api) => {
      console.log("[hangfive]", "Updating profile", action.payload);

      api.dispatch(startUpdate());

      try {
        const profileResponse = await postProfile(action.payload.email);
        console.log("[hangfive]", profileResponse);
        api.dispatch(updateSuccess("Profile updated"));
      } catch (err) {
        console.error(err);
        api.dispatch(updateFailure("Failed to update profile"));
      }
    },
  });
};
