import { PuzzleStats } from "../app/puzzleStatsSlice";
import { GameResult } from "../types/GameResult";
import GameState from "../types/GameState";
import { LetterPress } from "../types/LetterPress";
import UserStats from "../types/UserStats";
import * as api from "./api";
import { formatLocalTime } from "./dates";

export const roundToThreeDp = (num: number): number => {
  return Math.round((num + Number.EPSILON) * 1000) / 1000;
};

const defaultUserStats: UserStats = {
  daily: {
    latestGameNumber: 0,
    maxPlayStreak: 0,
    maxWinStreak: 0,
    playCount: 0,
    playStreak: 0,
    winCount: 0,
    winStreak: 0,
  },
  statsVersion: 2,
  total: {
    playCount: 0,
    winCount: 0,
  },
};

const getUserStats = (
  { daily: oldStats }: UserStats = defaultUserStats,
  gameNumber: number,
  gameResult: GameState
): UserStats => {
  const playCount = (oldStats?.playCount ?? 0) + 1;
  const winCount =
    (oldStats?.winCount ?? 0) + (gameResult === GameState.SOLVED ? 1 : 0);

  const currentWinStreak =
    gameResult === GameState.SOLVED
      ? (gameNumber == (oldStats?.latestGameNumber ?? 0) + 1
          ? oldStats?.winStreak ?? 0
          : 0) + 1
      : 0;

  const maxWinStreak = Math.max(oldStats?.maxWinStreak ?? 0, currentWinStreak);

  const currentPlayStreak =
    gameNumber == (oldStats?.latestGameNumber ?? 0) + 1
      ? (oldStats?.playStreak ?? 0) + 1
      : 1;

  const maxPlayStreak = Math.max(
    oldStats?.maxPlayStreak ?? 0,
    currentPlayStreak
  );

  const latestGameNumber = gameNumber;

  return {
    daily: {
      latestGameNumber,
      maxPlayStreak,
      maxWinStreak,
      playCount,
      playStreak: currentPlayStreak,
      winCount,
      winStreak: currentWinStreak,
    },
    statsVersion: 2,
    // TODO: This would neeed to be based on the new one, not copying from the daily ones
    total: {
      playCount: (oldStats?.playCount ?? 0) + 1,
      winCount,
    },
  };
};

const getPersistenceKey = () => localStorage.getItem("persistenceKey");

const getLetterPresses = (gameNumber: number) => {
  let existingString: string | null = null;
  if (getPersistenceKey() === gameNumber.toString()) {
    // get existing from localStorage
    existingString = localStorage.getItem("letterPress");
  }

  const existing = existingString
    ? (JSON.parse(existingString) as LetterPress[])
    : [];

  return existing;
};

const postStats = async (
  gameNumber: number,
  gameHash: string,
  letterPresses: LetterPress[],
  stats: UserStats,
  result: GameResult,
  duration: number | null,
  isArchive: boolean = false
): Promise<PuzzleStats> => {
  const date = new Date();
  const localTime = formatLocalTime(date);
  const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const statsResponse = await api.finishGame(
    gameNumber,
    gameHash,
    letterPresses,
    stats,
    localTime,
    localTimeZone,
    duration,
    isArchive,
    result.won
  );

  return statsResponse.data;
};
export default getUserStats;

export { defaultUserStats, getLetterPresses, postStats };
