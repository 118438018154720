import GameScreen from "./GameScreen";
import Toast from "./Toast";
import { useAppDispatch, useAppSelector } from "../hooks";
import {
  makeGuess,
  selectHasLoadingError,
  selectIsLoading,
  selectPuzzle,
  startPlaying,
} from "../app/gameSlice";
import { selectToastMessages } from "../app/toastSlice";
import { useEffect } from "react";
import ErrorScreen from "./ErrorScreen";
import { logInfo } from "../helpers/tracing";

const Gameplay = () => {
  const showError = useAppSelector(selectHasLoadingError);
  const isLoading = useAppSelector(selectIsLoading);

  const toastMessages = useAppSelector(selectToastMessages);

  const dispatch = useAppDispatch();

  const onChange = (input: string) => {
    dispatch(makeGuess({ letter: input }));
  };

  // calculate max number of letters not guessed in each word
  const puzzle = useAppSelector(selectPuzzle);

  useEffect(() => {
    dispatch(startPlaying());
  }, [dispatch, puzzle]);

  logInfo({ message: "Rendering GamePlay" });

  return (
    <>
      <GameScreen
        onKeyPress={onChange}
        loadNextGame={() => window.location.reload()}
        isLoading={isLoading ?? false}
      />

      {showError && <ErrorScreen />}

      {toastMessages.map((message, index) => (
        <Toast key={index} message={message} />
      ))}
    </>
  );
};

export default Gameplay;
