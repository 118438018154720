import {
  FaFacebook,
  FaInstagram,
  FaThreads,
  FaBluesky,
  FaTiktok,
  FaYoutube,
} from "react-icons/fa6";
import { BsSubstack } from "react-icons/bs";
import useHandleExternalLink from "../hooks/useHandleExternalLink";
import { useAppDispatch } from "../hooks";

const SocialIcons = () => {
  const handleExternalLink = useHandleExternalLink(useAppDispatch());

  return (
    <>
      <p className="text-center mb-2 text-xs">Follow us</p>
      <div className="flex justify-center space-x-4 mb-4">
        <a
          href="https://hangfive.substack.com"
          target="_blank"
          rel="noopener noreferrer"
          onClick={handleExternalLink}
          title="Subscribe to our newsletter"
        >
          <BsSubstack />
        </a>

        <a
          href="https://www.facebook.com/playhangfive"
          target="_blank"
          rel="noopener noreferrer"
          onClick={handleExternalLink}
          title="Follow us on Facebook"
        >
          <FaFacebook />
        </a>
        <a
          href="https://www.instagram.com/playhangfive"
          target="_blank"
          rel="noopener noreferrer"
          onClick={handleExternalLink}
          title="Follow us on Instagram"
        >
          <FaInstagram />
        </a>
        <a
          href="https://www.threads.net/@playhangfive"
          target="_blank"
          rel="noopener noreferrer"
          onClick={handleExternalLink}
          title="Follow us on Threads"
        >
          <FaThreads />
        </a>
        <a
          href="https://bsky.app/profile/playhangfive.com"
          target="_blank"
          rel="noopener noreferrer"
          onClick={handleExternalLink}
          title="Follow us on Bluesky"
        >
          <FaBluesky />
        </a>
        <a
          href="https://www.tiktok.com/@playhangfive"
          target="_blank"
          rel="noopener noreferrer"
          onClick={handleExternalLink}
          title="Follow us on TikTok"
        >
          <FaTiktok />
        </a>
        <a
          href="https://www.youtube.com/@playhangfive"
          target="_blank"
          rel="noopener noreferrer"
          onClick={handleExternalLink}
          title="Follow us on YouTube"
        >
          <FaYoutube />
        </a>
      </div>
    </>
  );
};

export default SocialIcons;
