import {
  PayloadAction,
  createAction,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { RootState } from "./store";

export const postFinishGame = createAction("postFinishGame");
export const fetchPuzzleStats = createAction<number>("fetchPuzzleStats");

export type PuzzleStats = {
  solvedRate?: number;
  userPercentile?: number;
  gameIndex?: number;
  userHasPlayed: boolean;
  playCount?: number;
};

const initialState: PuzzleStats = { userHasPlayed: false };

export const puzzleStatsSlice = createSlice({
  initialState,
  name: "puzzleStats",
  reducers: {
    setPuzzleStats: (state, action: PayloadAction<PuzzleStats>) => {
      state.solvedRate = action.payload.solvedRate;
      state.userPercentile = action.payload.userPercentile;
      state.gameIndex = action.payload.gameIndex;
      state.userHasPlayed = action.payload.userHasPlayed;
      state.playCount = action.payload.playCount;
    },
  },
});

export const selectPuzzleStats = (state: RootState) => state.puzzleStats;
export const selectPlayCount = createSelector(
  selectPuzzleStats,
  (stats) => stats.playCount
);

export const { setPuzzleStats } = puzzleStatsSlice.actions;
export default puzzleStatsSlice.reducer;
