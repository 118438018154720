import { setModalVisibility } from "../app/modalSlice";
import {
  selectIsProfileUpdating,
  selectProfileUpdateError,
  selectProfileUpdateResult,
  updateProfile,
} from "../app/profileSlice";
import { selectPlayCount } from "../app/puzzleStatsSlice";
import { getClassName } from "../helpers/classNames";
import { useAppDispatch, useAppSelector } from "../hooks";
import Modal from "./Modal";
import { useState } from "react";

export const MilestoneForm = () => {
  const milestoneAmount = 1000000;
  const playCount = useAppSelector(selectPlayCount);
  const isUpdating = useAppSelector(selectIsProfileUpdating);
  const updateResult = useAppSelector(selectProfileUpdateResult);
  const updateError = useAppSelector(selectProfileUpdateError);
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState("");

  if (playCount !== milestoneAmount) {
    return null;
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    dispatch(updateProfile({ email }));
  };

  return (
    <Modal>
      <div className="">
        <h1 className="text-md font-bold text-center mb-1">
          🎉 Congratulations! 🎉
        </h1>
        <h1 className="text-md font-bold text-center mb-2">
          You have just finished playing our millionth game!
        </h1>
        <p className="text-xs mb-4">
          Thank you so much for playing Hang Five and helping us get to this
          milestone.
        </p>
        <p className="text-xs mb-4">
          We'd love to get in touch and send you a t-shirt if you're keen.
        </p>
        <p className="text-xs mb-4">
          Please let us know your email address and we'll be in touch.
        </p>
        {updateResult ? (
          <>
            <p className="text-center text-xs font-bold">
              {updateError && (
                <>
                  This is embarrassing, something went wrong.
                  <br /> Could you please just email us at{" "}
                  <a className="underline" href="mailto:hello@playhangfive.com">
                    hello@playhangfive.com
                  </a>
                  ?
                </>
              )}
              {!updateError && (
                <>
                  Thanks, we've saved your email address. <br />
                  We'll be in touch soon!
                </>
              )}
            </p>
            <p className="text-xs mt-4 text-center">
              <a
                className="underline"
                href="#"
                onClick={(e) => {
                  dispatch(setModalVisibility(false));
                  e.preventDefault();
                }}
              >
                Close
              </a>
            </p>
          </>
        ) : (
          <>
            <form onSubmit={onSubmit}>
              <p className="text-center">
                <input
                  className="rounded-lg p-2 border dark:border-slate-500"
                  type="email"
                  name="email"
                  placeholder="Your email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  disabled={isUpdating}
                ></input>
                <input
                  className={`ml-1 rounded-lg p-2 ${getClassName(
                    "primary",
                    isUpdating
                  )}`}
                  type="submit"
                  value="Send"
                  disabled={isUpdating}
                ></input>
              </p>
            </form>
            <p className="text-xs mt-4 text-center">
              Your personal information will be used in accordance with our{" "}
              <a className="underline" href="https://zemobo.com/privacy">
                privacy policy
              </a>
              .
            </p>
            <p className="text-xs mt-4 text-center">
              <a
                className="underline"
                href="#"
                onClick={(e) => {
                  dispatch(setModalVisibility(false));
                  e.preventDefault();
                }}
              >
                No thanks, I'd rather stay private
              </a>
            </p>
          </>
        )}
      </div>
    </Modal>
  );
};
