import ReactDOM from "react-dom/client";

import "./index.css";
import { registerServiceWorker } from "./helpers/registerServiceWorker.ts";

import "./helpers/tracing.ts";
import { Root } from "./Root.tsx";
import { isNative } from "./helpers/system.ts";
import { isInMigrationPath } from "./helpers/migration.ts";

registerServiceWorker();

const disableAds = import.meta.env.VITE_DISABLE_ADS === "1";

const loadWebAds = !disableAds && !isNative() && !isInMigrationPath();

if (loadWebAds) {
  window.nitroAds = window.nitroAds || {
    addUserToken: function () {
      // eslint-disable-next-line prefer-rest-params
      window.nitroAds.queue.push(["addUserToken", arguments]);
    },
    createAd: function () {
      return new Promise((e) => {
        // eslint-disable-next-line prefer-rest-params
        window.nitroAds.queue.push(["createAd", arguments, e]);
      });
    },
    queue: [],
  };

  const scriptElement = document.createElement("script");
  scriptElement.src = "https://s.nitropay.com/ads-1846.js";
  scriptElement.async = true;
  document.head.appendChild(scriptElement);
}

ReactDOM.createRoot(document.getElementById("root")!).render(<Root />);
