import {
  clearGame,
  loadPersistedGameState,
  loadPersistedGameStateComplete,
  loadPuzzle,
  makeGuess,
  persistCurrentGameState,
  postProgress,
  selectGameIndex,
  selectGameStatus,
  selectGuessedLetters,
  selectIsArchive,
  selectIsLearning,
  selectLetterPresses,
  selectLivesLeft,
  selectOnlyVowelsLeft,
  selectPuzzle,
  selectPuzzleLetters,
  selectStats,
  setJustFinished,
  trackShare,
} from "./gameSlice";
import { isVowel } from "../helpers/letters";
import { startAppListening } from "./listenerMiddleware";
import { showToast } from "./toastSlice";
import { track, TrackingEvent } from "./trackingSlice";
import GameStatus from "../types/GameState";
import isEqual from "lodash/isEqual";
import { postShareEvent, sendProgress } from "../helpers/api";
import { formatLocalTime } from "../helpers/dates";
import { logInfo } from "../helpers/tracing";
import {
  GuideMessage,
  selectGuideMessage,
  setGuideMessage,
} from "./learningSlice";
import { setStats } from "./gameSlice";
import getUserStats from "../helpers/stats";
import { deserializeGame, serializeGame } from "../helpers/persistence";
import { postFinishGame } from "./puzzleStatsSlice";

const progressSentForGames: number[] = [];

const hasSentProgressForGame = (gameIndex: number) => {
  const hasSent = progressSentForGames.includes(gameIndex);

  if (!hasSent) {
    progressSentForGames.push(gameIndex);
  }

  return hasSent;
};

export const registerGameListeners = () => {
  startAppListening({
    actionCreator: makeGuess,
    effect: async (action, api) => {
      logInfo({ message: "Listening for makeGuess" });

      const { letter: guessedLetter } = action.payload;

      // has this letter already been tried?
      const allGuessedLetters = selectGuessedLetters(api.getOriginalState());
      const alreadyGuessed = allGuessedLetters.includes(guessedLetter);

      if (alreadyGuessed) {
        return;
      }

      const onlyVowelsLeft = selectOnlyVowelsLeft(api.getState());
      const guessIsVowel = isVowel(guessedLetter);

      if (
        !onlyVowelsLeft &&
        guessIsVowel &&
        selectLivesLeft(api.getOriginalState()) === 1
      ) {
        api.dispatch(
          showToast({
            message:
              "You only have one life left. Buying a vowel will use it up and you'll lose the game.",
          })
        );
      }

      const gameStatus = selectGameStatus(api.getState());

      if (gameStatus !== GameStatus.IN_PROGRESS) {
        api.dispatch(setJustFinished(true));
      }

      const isLearning = selectIsLearning(api.getState());

      if (isLearning) {
        const puzzleLetters = selectPuzzleLetters(api.getState());

        const isCorrect = puzzleLetters.includes(guessedLetter);

        if (guessIsVowel && !onlyVowelsLeft) {
          if (isCorrect) {
            api.dispatch(setGuideMessage(GuideMessage.earlyVowel));
          } else {
            api.dispatch(setGuideMessage(GuideMessage.earlyVowel)); // TODO: Have a message for incorrect early vowel
          }
        }

        const livesLeftAfterGuess = selectLivesLeft(api.getState());

        if (livesLeftAfterGuess === 0) {
          api.dispatch(setGuideMessage(GuideMessage.lostGame));
        }

        if (
          onlyVowelsLeft &&
          selectGuideMessage(api.getState()) !== GuideMessage.onlyVowelsLeft &&
          selectGameStatus(api.getState()) === GameStatus.IN_PROGRESS
        ) {
          api.dispatch(setGuideMessage(GuideMessage.onlyVowelsLeft));
        }
      }

      if (gameStatus !== GameStatus.IN_PROGRESS) {
        const state = api.getState();

        const oldStats = selectStats(state);
        const gameIndex = selectGameIndex(state);

        const newStats = getUserStats(oldStats, gameIndex, gameStatus);

        console.log("[hangfive] Updating stats", newStats);

        api.dispatch(setStats(newStats));

        const isLearning = selectIsLearning(state);

        const shouldPostFinishGame = !isLearning;

        // This gets called twice. Once from undefined, and once from the empty state to this one, I think
        // First one is called when setting initial state, second is when migrate action happens.
        // This is probably not the way to have done this

        if (shouldPostFinishGame) {
          api.dispatch(postFinishGame());
        }
      }

      api.dispatch(persistCurrentGameState());
    },
  });

  startAppListening({
    actionCreator: persistCurrentGameState,
    effect: async (_action, api) => {
      const game = api.getState().game;

      serializeGame(game);
    },
  });

  startAppListening({
    actionCreator: setStats,
    effect: async (_action, api) => {
      api.dispatch(persistCurrentGameState());
    },
  });

  startAppListening({
    actionCreator: loadPuzzle,
    effect: async (action, api) => {
      logInfo({ message: "Listening for loadPuzzle" });

      if (isEqual(api.getOriginalState(), api.getState())) {
        return;
      }

      const previousGameIndex = selectGameIndex(api.getOriginalState());

      if (!action.payload.learning) {
        const { gameIndex } = action.payload;

        let eventName: TrackingEvent = "start_game";

        if (previousGameIndex === gameIndex) {
          const status = selectGameStatus(api.getState());

          if (status === GameStatus.IN_PROGRESS) {
            eventName = "continue_game";
          } else {
            eventName = "return_to_finished_game";
          }
        }

        api.dispatch(
          track({
            eventName,
            properties: {
              gameIndex: gameIndex,
            },
          })
        );
      }

      if (selectIsLearning(api.getState())) {
        api.dispatch(setGuideMessage(GuideMessage.firstGameIntro));
      }
    },
  });

  startAppListening({
    actionCreator: postProgress,
    effect: async (_action, api) => {
      logInfo({ message: "Listening for postProgress" });

      const gameStatus = selectGameStatus(api.getState());

      if (gameStatus !== GameStatus.IN_PROGRESS) {
        return;
      }

      const puzzle = selectPuzzle(api.getState());
      const letterPresses = selectLetterPresses(api.getState());
      const isArchive = selectIsArchive(api.getState());
      const isLearning = selectIsLearning(api.getState());

      if (!isLearning) {
        const { gameIndex, gameHash } = puzzle;

        if (hasSentProgressForGame(gameIndex)) {
          return;
        }

        const date = new Date();
        const localTime = formatLocalTime(date);
        const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        await sendProgress(
          gameIndex,
          gameHash,
          letterPresses,
          localTime,
          localTimeZone,
          isArchive
        );
      }
    },
  });

  startAppListening({
    actionCreator: trackShare,
    effect: async (action) => {
      const { gameNumber, shareMethod } = action.payload;

      postShareEvent(gameNumber, shareMethod);
    },
  });

  startAppListening({
    actionCreator: clearGame,
    effect: async (_action, api) => {
      api.dispatch(setGuideMessage(undefined));
    },
  });

  startAppListening({
    actionCreator: loadPersistedGameState,
    effect: async (_action, api) => {
      const persistedState = deserializeGame();

      // TODO: When we do multiple games, read from payload to get the gameIndex

      const gameIndex = persistedState.puzzle.gameIndex;

      console.log("[hangfive] Loading persisted game state for game", {
        gameIndex,
      });

      // let { gameIndex } = action.payload;

      // if (!gameIndex) {
      //   gameIndex = selectGameIndex(api.getState());
      // }

      console.log(
        "[hangfive] Loading persisted game state for game",
        gameIndex
      );

      console.log("[hangfive] persistedState", persistedState);

      api.dispatch(
        loadPersistedGameStateComplete({ gameIndex, gameState: persistedState })
      );
    },
  });
};
