import { MIGRATE_TO_NATIVE_PATH } from "../constants/paths";
import { serializeGame, serializeStats } from "../helpers/persistence";
import { getDeviceId, getUid, replaceUid } from "../helpers/system";
import { GamePayload } from "../types/GamePayload";
import { MigrationPayload } from "../types/MigrationPayload";
import {
  selectGameStartTime,
  selectIsArchive,
  selectIsLearning,
  selectStats,
} from "./gameSlice";
import { startAppListening } from "./listenerMiddleware";
import {
  beginCompleteMigration,
  finishCompleteMigration,
  handBackToNative,
  selectMigrationId,
  selectMigrationPlatform,
  startMigration,
} from "./migrationSlice";
import { setHasMigrated } from "./settingsSlice";

export const registerMigrationListener = () => {
  startAppListening({
    actionCreator: startMigration,
    effect: async (_, api) => {
      const state = api.getState();

      const isLearning = selectIsLearning(state);
      const isArchive = selectIsArchive(state);
      const startTimestamp = selectGameStartTime(state);

      console.log("[hangfive] state", state);

      // send them to migration place
      let game = null;
      let letterPresses = null;

      if (!isLearning && !isArchive && startTimestamp) {
        game = JSON.parse(localStorage.getItem("game") ?? "{}") as GamePayload;
        letterPresses = JSON.parse(localStorage.getItem("letterPress") ?? "[]");
      }

      console.log("[hangfive] localStorage.game", localStorage.getItem("game"));
      console.log(
        "[hangfive] localStorage.stats",
        localStorage.getItem("stats")
      );
      console.log(
        "[hangfive] localStorage.letterPress",
        localStorage.getItem("letterPress")
      );

      const deviceId = getDeviceId();
      const uid = getUid();
      const stats = selectStats(state);
      const migrationId = selectMigrationId(state);

      const migrationPayload: MigrationPayload = {
        deviceId,
        game,
        letterPresses,
        migrationId: migrationId!,
        stats,
        uid,
      };

      const encodedPayload = btoa(JSON.stringify(migrationPayload));

      let nextUrl: string | undefined;

      const migrationPlatform = selectMigrationPlatform(api.getState());

      switch (migrationPlatform) {
        case "android":
          nextUrl = "com.playhangfive://migrate?payload=" + encodedPayload;
          break;
        case "ios":
        case "ipad":
          nextUrl = `com.playhangfive://${window.location.host}/${MIGRATE_TO_NATIVE_PATH}/ios#payload=${encodedPayload}`;
          break;
      }

      api.dispatch(setHasMigrated());
      if (nextUrl) {
        api.dispatch(handBackToNative(nextUrl));
      }
    },
  });

  startAppListening({
    actionCreator: beginCompleteMigration,
    effect: async (action, api) => {
      const payload = action.payload;
      // these all have side-effects. move to observer later on
      // migrate the user
      replaceUid(payload.uid);
      if (payload.stats) {
        serializeStats(payload.stats);
      }

      if (payload.game) {
        const gameState = {
          isArchive: false,
          isLearning: false,
          letterPresses: payload.letterPresses,
          puzzle: {
            date: "",
            gameHash: payload.game.gameHash,
            gameIndex: payload.game.gameIndex,
            puzzleId: payload.game.puzzleId,
            theme: "",
            words: [],
          },
        };

        serializeGame(gameState);
      }

      api.dispatch(finishCompleteMigration());
    },
  });
};
