import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { deserializeSettings } from "../helpers/persistence";

/**
 * Represents the state of the settings.
 * @property hasMigrated - Indicates that the user migrated to the native app.
 */
type SettingsState = {
  /**
   * Indicates that the user migrated to the native app.
   */
  hasMigrated: boolean;
};

export const settingsSlice = createSlice({
  initialState: deserializeSettings(),
  name: "settings",
  reducers: {
    setHasMigrated: (state: SettingsState) => {
      state.hasMigrated = true;
    },
  },
});

export const selectHasMigrated = (state: RootState) =>
  state.settings.hasMigrated;

export const { setHasMigrated } = settingsSlice.actions;
export default settingsSlice.reducer;
export type { SettingsState };
