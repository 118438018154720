import {
  selectGameStatus,
  selectIsArchive,
  selectIsLearning,
  selectLetterPresses,
  selectLostLifeReasons,
  selectPuzzle,
  selectScore,
  selectStats,
  selectTimeTaken,
} from "./gameSlice";
import { startAppListening } from "./listenerMiddleware";
import {
  fetchPuzzleStats,
  postFinishGame,
  setPuzzleStats,
} from "./puzzleStatsSlice";
import { postStats as postStatsHelper } from "../helpers/stats";
import GameStatus from "../types/GameState";
import LifeStatus from "../types/LifeStatus";
import { GameResult } from "../types/GameResult";
import { getStats } from "../helpers/api";
import { logInfo } from "../helpers/tracing";

export const registerPuzzleStatsListeners = async () => {
  startAppListening({
    actionCreator: postFinishGame,
    effect: async (_action, api) => {
      const stats = selectStats(api.getState());

      logInfo({ message: "Posting stats" });
      const puzzle = selectPuzzle(api.getState());
      const status = selectGameStatus(api.getState());
      const lostLifeReasons = selectLostLifeReasons(api.getState());
      const isArchive = selectIsArchive(api.getState());
      const isLearning = selectIsLearning(api.getState());

      if (!isLearning) {
        const score = selectScore(api.getState());
        const letterPresses = selectLetterPresses(api.getState());
        const duration = selectTimeTaken(api.getState());

        const result: GameResult = {
          score: score ?? -1, // TODO: Handle this better
          vowelsBought: lostLifeReasons.filter((r) => r == LifeStatus.VOWEL)
            .length,
          won: status === GameStatus.SOLVED,
        };

        const gameStats = await postStatsHelper(
          puzzle.gameIndex,
          puzzle.gameHash,
          letterPresses,
          stats!,
          result,
          duration ? duration / 1000 : null,
          isArchive
        );

        api.dispatch(setPuzzleStats({ ...gameStats, userHasPlayed: true }));
      }
    },
  });

  startAppListening({
    actionCreator: fetchPuzzleStats,
    effect: async (action, api) => {
      const stats = await getStats(action.payload);

      api.dispatch(
        setPuzzleStats({
          ...stats.data,
          userHasPlayed: !!stats.data.userPercentile,
        })
      );
    },
  });
};
