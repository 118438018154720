import rot13 from "ebg13";
import { RequestType, request } from "./requests";
import { logError } from "./tracing";
import axios from "axios";
import { AppDispatch } from "../app/store";
import { loadPuzzle, setHasLoadingError, startLoading } from "../app/gameSlice";
import { formatLocalDate } from "./dates";

type Puzzle = {
  puzzleId: string;
  date: string;
  words: string[];
  theme: string;
  gameIndex: number;
  gameHash: string;
  link?: { url: string; text: string };
};

type PuzzleResponse = {
  puzzleId: string;
  gameHash: string;
  gameIndex: number;
  date: string;
  theme: string;
  words: string[];
  clear?: boolean;
  link?: { url: string; text: string };
};

type Manifest = {
  lastPuzzleDate: string;
};

class PuzzleService {
  async getGame(
    date: Date | string,
    isFallback: boolean = false
  ): Promise<Puzzle> {
    const puzzlePath = this.getPuzzlePath(date);

    try {
      const gameAnswers = await request(
        "GET",
        puzzlePath,
        undefined,
        true,
        RequestType.Content
      );

      return this.getGameFromResponse(gameAnswers.data);
    } catch (err) {
      if (
        !isFallback &&
        axios.isAxiosError(err) &&
        err.response &&
        err.response.status === 404
      ) {
        const fallbackDate = await this.getDateFromManifest();

        if (fallbackDate) {
          return this.getGame(fallbackDate, true);
        }
      }

      logError(err, { message: "Error getting game" });
      throw err;
    }
  }

  private getPuzzlePath(date: Date | string): string {
    let dateString: string;

    if (typeof date === "string") {
      dateString = date;
    } else {
      dateString = formatLocalDate(date);
    }

    return `/content/${dateString}.json`;
  }

  private async getDateFromManifest(): Promise<string | null> {
    const manifestPath = this.getPuzzlePath("manifest");

    try {
      const manifestResponse = await request(
        "GET",
        manifestPath,
        undefined,
        true,
        RequestType.Content
      );

      const manifest = manifestResponse.data as Manifest;

      if (manifest && manifest.lastPuzzleDate) {
        return manifest.lastPuzzleDate;
      }

      return null;
    } catch (err) {
      logError(err, { message: "Error getting manifest" });
      return null;
    }
  }

  private getGameFromResponse({
    puzzleId,
    date,
    gameHash,
    gameIndex,
    words: rawWords,
    theme: rawTheme,
    clear,
    link,
  }: PuzzleResponse): Puzzle {
    const words = clear ? rawWords : rawWords.map((word) => rot13(word));
    const theme = clear ? rawTheme : rot13(rawTheme);

    return { date, gameHash, gameIndex, link, puzzleId, theme, words };
  }
}

export const loadTodaysGame = async (dispatch: AppDispatch) => {
  const loadGame = async () => {
    dispatch(startLoading());

    const puzzleService = new PuzzleService();
    const game = await puzzleService.getGame(
      /*overrideGameDate ?? */ new Date()
    );

    const { puzzleId, date, gameHash, gameIndex, theme, words, link } = game;

    dispatch(
      loadPuzzle({ date, gameHash, gameIndex, link, puzzleId, theme, words })
    );
  };

  loadGame().catch((error) => {
    logError(error, { message: "Error loading game" });
    dispatch(setHasLoadingError(true));
  });
};

export type { Puzzle };
export default PuzzleService;
