import { ReactNode, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "./Button";
import { useAppDispatch, useAppSelector } from "../hooks";
import {
  selectDailyStats,
  selectDate,
  selectGameIndex,
  selectGameStatus,
} from "../app/gameSlice";
import GameStatus from "../types/GameState";
import { loadTodaysGame } from "../helpers/puzzles";
import { formatFullDate } from "../helpers/dates";
import ExternalLinkIcon from "./icons/ExternalLinkIcon";
import HangFiveIcon from "./icons/HangFiveIcon";
import { LEARN_PATH } from "../constants/paths";
import { useMessage } from "../hooks/useMessage";
import { getPlatform, getVersionNumber, isNative } from "../helpers/system";
import { logInfo } from "../helpers/tracing";
import { postNativeBridgeMessage } from "../helpers/nativeBridge";
import { selectHasMigrated } from "../app/settingsSlice";
import { ModalType, showModal } from "../app/modalSlice";
import { selectSkipNativePrompt } from "../app/migrationSlice";
import useHandleExternalLink from "../hooks/useHandleExternalLink";
import SocialIcons from "./SocialIcons";

const ButtonList = ({ buttons }: { buttons: ReactNode[] }) => (
  <ul className="w-[15rem] mx-auto">
    {buttons.map((button, index) => (
      <li className="my-2" key={index}>
        {button}
      </li>
    ))}
  </ul>
);

const StartScreen = () => {
  // const [features, setFeatures] = useState<string[]>([]);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const buttons = [];

  const playCount = useAppSelector(selectDailyStats)?.playCount;

  const isFirstGame = !playCount || playCount === 0;

  const platform = getPlatform();

  logInfo({ message: "Rendering StartScreen" });

  const playedTodays =
    useAppSelector(selectGameStatus) !== GameStatus.IN_PROGRESS;

  const gameDate = useAppSelector(selectDate);
  const gameIndex = useAppSelector(selectGameIndex);
  const hasMigrated = useAppSelector(selectHasMigrated);
  const skipNativePrompt = useAppSelector(selectSkipNativePrompt) || isNative();

  const playMessage = playedTodays
    ? "See today's puzzle"
    : "Play today's puzzle";

  const playLearningGameMessage = useMessage(
    "start_screen_play_learning_game"
  ).body;

  const howToPlayMessage = useMessage("start_screen_how_to_play").body;
  const myStatisticsMessage = useMessage("start_screen_stats").body;

  if (isFirstGame) {
    buttons.push(
      <Button className="w-full" onClick={() => navigate("how-to-play")}>
        {howToPlayMessage}
      </Button>
    );

    buttons.push(
      <Button className="w-full" onClick={() => navigate(LEARN_PATH + "0")}>
        {playLearningGameMessage}
      </Button>
    );

    buttons.push(
      <Button
        className="w-full"
        onClick={() => navigate("play")}
        variant="secondary"
      >
        {playMessage}
      </Button>
    );
  } else {
    buttons.push(
      <Button className="w-full" onClick={() => navigate("play")}>
        {playMessage}
      </Button>
    );
    buttons.push(
      <Button
        variant="secondary"
        className="w-full"
        onClick={() => navigate("how-to-play")}
      >
        {howToPlayMessage}
      </Button>
    );
    buttons.push(
      <Button
        variant="secondary"
        className="w-full"
        onClick={() => navigate("stats")}
      >
        {myStatisticsMessage}
      </Button>
    );
  }

  const handleExternalLink = useHandleExternalLink(dispatch);

  useEffect(() => {
    // TODO: Not this - loadTodaysGame is the thing that calls the API and we don't want that here
    if (!playedTodays) {
      loadTodaysGame(dispatch);
    }
  }, [dispatch, playedTodays]);

  const stats = useAppSelector(selectDailyStats);

  const showMigrateOption = isNative() && stats?.playCount === 0;

  const isIPhone = /iPhone/.test(navigator.userAgent);

  const messageStartPuzzlePrefix = useMessage("start_puzzle_prefix").body;

  const beginMigration = () => {
    postNativeBridgeMessage("startMigration");
  };

  useEffect(() => {
    if (hasMigrated && !skipNativePrompt) {
      setTimeout(() => {
        dispatch(showModal(ModalType.UseNative));
      }, 100);
    }
  }, [hasMigrated, skipNativePrompt, dispatch]);

  const userHasNZLanguage =
    navigator.language === "en-NZ" ||
    navigator.language === "mi-NZ" ||
    navigator.language === "mi";
  const userHasNZTimeZone =
    Intl.DateTimeFormat().resolvedOptions().timeZone === "Pacific/Auckland" ||
    Intl.DateTimeFormat().resolvedOptions().timeZone === "Pacific/Chatham";

  const userIsInNZ = userHasNZLanguage || userHasNZTimeZone;

  return (
    <div className="h-full grid grid-rows-[1fr_auto]">
      <div className="text-center mt-0 mx-4 self-center">
        <HangFiveIcon
          className="mx-auto mb-5 mt-3 shadow rounded-xl"
          size="8rem"
        />

        <h1 className="font-bold text-xl mb-2">
          {useMessage("start_welcome").body}
        </h1>
        <p className="text-sm mb-1 mx-9">{useMessage("start_body_1").body}</p>
        <p className="text-sm mb-6 mx-9">{useMessage("start_body_2").body}</p>

        {showMigrateOption && (
          <p className="text-lg mb-6">
            Already played Hang Five on this device?
            <br />
            <a
              className="font-bold underline"
              href="#"
              onClick={(e) => {
                beginMigration();
                e.preventDefault();
              }}
            >
              Bring your stats to the app
            </a>
          </p>
        )}

        <ButtonList buttons={buttons} />

        <div className="mt-6 mb-3">
          <p className="text-sm font-medium mb-1">
            {gameDate ? formatFullDate(new Date(gameDate)) : <>&nbsp;</>}
          </p>
          <p className="text-xs">
            {gameDate ? (
              <>
                {messageStartPuzzlePrefix}
                {gameIndex}
              </>
            ) : (
              <>&nbsp;</>
            )}
          </p>
        </div>
      </div>
      <div>
        <SocialIcons />
      </div>
      <div
        className={`self-end mb-4 mx-4 ${isIPhone ? "m-7" : ""} ${
          platform === "ios" ? "mb-8 mx-6" : ""
        }`}
      >
        <div className="text-xs mt-0 flex items-justify items-end">
          <div className="flex-1">
            <Link to="about" className="opacity-15 mb-0.5 block">
              {getVersionNumber()}
            </Link>
            <a
              href="https://zemobo.com/"
              className="flex items-center hover:underline"
              target="_blank"
              onClick={handleExternalLink}
            >
              {
                useMessage(
                  userIsInNZ ? "made_in_nz_by_zemobo" : "made_by_zemobo"
                ).body
              }
              <ExternalLinkIcon
                className="ml-2 fill-gray-500 dark:fill-slate-400"
                size={11}
              />
            </a>
          </div>
          <div className="flex-1 ">
            <a
              href="https://zemobo.com/privacy"
              className="flex items-center justify-end hover:underline"
              target="_blank"
              onClick={handleExternalLink}
            >
              {useMessage("privacy_policy").body}
              <ExternalLinkIcon
                className="ml-2 fill-gray-500 dark:fill-slate-400"
                size={11}
              />
            </a>
          </div>
        </div>
        <div className="text-right text-xs">
          <span data-ccpa-link="1"></span>
        </div>
      </div>
    </div>
  );
};

export default StartScreen;
