type Props = {
  value?: string;
  label: string;
  size?: "small" | "normal";
};

export const StatPanel = ({ label, size = "normal", value }: Props) => {
  return (
    <div
      className={`text-center font-normal ${
        size === "small" ? "text-lg" : "text-2xl"
      }`}
    >
      {value ?? "00"}
      <span className="text-xs block">{label}</span>
    </div>
  );
};
