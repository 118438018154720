import { serializeSettings } from "../helpers/persistence";
import GameStatus from "../types/GameState";
import {
  clearGame,
  postProgress,
  selectDailyStats,
  selectGameIndex,
  selectGameStatus,
  selectIsLearning,
  selectLetterPresses,
  selectPuzzle,
} from "./gameSlice";
import { selectGameIndex as selectLearningGameIndex } from "./learningSlice";
import { observeStore } from "./observeStore";
import { store } from "./store";
import { track } from "./trackingSlice";
import {
  clearLetterPress as clearLetterPressHelper,
  clearGame as clearGameHelper,
} from "../helpers/persistence";
import { logInfo } from "../helpers/tracing";
import { postNativeBridgeMessage } from "../helpers/nativeBridge";

export const registerObservers = () => {
  observeStore(store, selectGameStatus, (gameStatus, prevGameStatus) => {
    logInfo({
      message: `Game status changed from ${prevGameStatus} to ${gameStatus}`,
    });
    if (
      prevGameStatus === GameStatus.IN_PROGRESS &&
      gameStatus !== prevGameStatus
    ) {
      const gameNumber = store.getState().game.puzzle.gameIndex;
      const gameResult = gameStatus === GameStatus.SOLVED ? "Won" : "Lost";

      const isLearning = selectIsLearning(store.getState());
      const learningIndex = selectLearningGameIndex(store.getState());

      const totalGames = selectDailyStats(store.getState())?.playCount;

      // Track it
      store.dispatch(
        track({
          eventName: isLearning ? "finish_tutorial" : "finish_game",
          properties: {
            gameCount: (totalGames ?? 0) + 1,
            gameIndex: isLearning ? learningIndex : gameNumber,
            gameResult,
          },
        })
      );

      if (totalGames === 0) {
        store.dispatch(
          track({
            eventName: "finish_first_game",
          })
        );
      }

      postNativeBridgeMessage("finishGame");
    }
  });

  // Persist settings to localStorage
  observeStore(
    store,
    (state) => state.settings,
    (settings) => {
      if (settings) {
        serializeSettings(settings);
      }
    }
  );

  // Clear game if puzzle index changes
  observeStore(store, selectGameIndex, (gameIndex, prevGameIndex) => {
    if (
      prevGameIndex !== undefined &&
      prevGameIndex !== 0 && // Added this to prevent clearing game on first load, but it means you can't clear the game if you're on the first game
      gameIndex !== prevGameIndex
    ) {
      const isLearning = selectIsLearning(store.getState());
      const wasLearning = prevGameIndex > 10000;

      if (!isLearning && !wasLearning) {
        clearGameHelper();
        clearLetterPressHelper();

        console.log("[hangfive] Clearing game and letterPresses", {
          gameIndex,
          prevGameIndex,
        });

        store.dispatch(clearGame());
      }
    }
  });

  observeStore(store, selectLetterPresses, (letterPresses) => {
    const puzzle = selectPuzzle(store.getState());
    if (!puzzle.words?.length) {
      // There is no puzzle, so nothing else to do
      return;
    }

    logInfo({ message: "Observing letterPresses changes" });

    const isLearning = selectIsLearning(store.getState());

    if (letterPresses.length <= 1) {
      return;
    }

    if (letterPresses.length === 1) {
      const learningIndex = selectLearningGameIndex(store.getState());

      store.dispatch(
        track({
          eventName: "press_letter",
          properties: {
            gameIndex: isLearning ? learningIndex : puzzle.gameIndex,
          },
        })
      );
    }

    store.dispatch(postProgress());
  });
};
