import {
  createAction,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import GameStatus from "../types/GameState";
import { getLettersInWords, isVowel } from "../helpers/letters";
import LifeStatus from "../types/LifeStatus";
import { Puzzle } from "../helpers/puzzles";
import { RootState } from "./store";
import UserStats from "../types/UserStats";
import { LetterPress } from "../types/LetterPress";

const maxLives = 5;
const santaSuffix = "12-24";

// if (process.env.NODE_ENV === "development") {
//   santaSuffix = "12-08";
// }

interface GameState {
  puzzle: Puzzle;
  puzzleId?: string;
  hasLoadingError?: boolean;
  isArchive: boolean;
  isLearning: boolean;
  isLoading?: boolean;
  hasHydrated?: boolean;
  learningEndText?: string;
  stats?: UserStats;
  letterPresses: LetterPress[];
  justFinished?: boolean;
  showSanta?: boolean;
  startTimestamp?: number;
  startTimestampGameIndex?: number;
}

const initialState: GameState = {
  hasHydrated: false,
  isArchive: false,
  isLearning: false,
  letterPresses: [],
  puzzle: {
    date: "",
    gameHash: "",
    gameIndex: 0,
    puzzleId: "",
    theme: "",
    words: [],
  },
};

interface MakeGuessPayload {
  letter: string;
}

interface PuzzlePayload {
  puzzleId: string;
  date: string;
  gameIndex: number;
  link?: { url: string; text: string };
  theme: string;
  words: string[];
  gameHash: string;
  archive?: boolean;
  learning?: boolean;
}

interface LearningPuzzlePayload {
  theme: string;
  words: string[];
  learning: true;
}

interface MigratedGame {
  game: {
    correctGuesses: string[];
    incorrectGuesses: string[];
    lostLifeReasons: LifeStatus[];
    status: GameStatus;
  } | null;
  stats: UserStats;
}

const getRemainingLetters = (
  validLetters: string[],
  correctGuesses: string[]
): string[] =>
  validLetters.filter((letter) => !correctGuesses.includes(letter));

const getOnlyVowelsLeft = (
  validLetters: string[],
  correctGuesses: string[]
): boolean => getRemainingLetters(validLetters, correctGuesses).every(isVowel);

const getLetterPressesForGameIndex = (
  letterPresses: LetterPress[],
  gameIndex: number
): LetterPress[] =>
  letterPresses.filter(
    (lp) =>
      (lp.gameIndex === gameIndex || lp.gameIndex === undefined) &&
      lp.letter !== ""
  );

const getLostLifeReasons = (
  puzzleLetters: string[],
  letterPresses: LetterPress[]
) => {
  const reasons: LifeStatus[] = [];

  if (puzzleLetters.length === 0) {
    return reasons;
  }

  let remainingLetters = puzzleLetters;

  for (const { letter } of letterPresses) {
    if (remainingLetters.includes(letter)) {
      if (isVowel(letter)) {
        const onlyVowelsRemain = remainingLetters.every(isVowel);

        if (!onlyVowelsRemain) {
          reasons.push(LifeStatus.VOWEL);
        }
      }
    } else {
      reasons.push(LifeStatus.INCORRECT);
    }

    remainingLetters = remainingLetters.filter((l) => l !== letter);
  }
  return reasons;
};

const getLivesLeft = (
  puzzleLetters: string[],
  letterPresses: LetterPress[],
  gameIndex: number
) =>
  maxLives -
  getLostLifeReasons(
    puzzleLetters,
    letterPresses.filter((lp) => lp.gameIndex === gameIndex)
  ).length;

const getCorrectGuesses = (
  letterPresses: LetterPress[],
  validLetters: string[]
) => {
  const correctGuesses = letterPresses.reduce((correctGuesses, { letter }) => {
    if (validLetters.includes(letter)) {
      correctGuesses.push(letter);
    }

    return correctGuesses;
  }, [] as string[]);

  return correctGuesses;
};

const getGameStatus = (
  letterPresses: LetterPress[],
  validLetters: string[],
  correctGuesses: string[],
  gameIndex: number,
  isLearning: boolean
) => {
  // figure out game status based solely on letterPresses
  if (letterPresses.length === 0 || validLetters.length === 0) {
    return GameStatus.IN_PROGRESS;
  }

  const lastPress = letterPresses[letterPresses.length - 1];

  if (lastPress && lastPress.letter === "") {
    return GameStatus.IN_PROGRESS;
  }

  const isSolved = validLetters.every((letter) =>
    correctGuesses.includes(letter)
  );

  if (isSolved) return GameStatus.SOLVED;

  if (isLearning) {
    return GameStatus.IN_PROGRESS;
  }

  if (getLivesLeft(validLetters, letterPresses, gameIndex) === 0) {
    return GameStatus.LOST;
  }

  return GameStatus.IN_PROGRESS;
};

export const gameSlice = createSlice({
  initialState,
  name: "game",
  reducers: {
    clearGame: (state) => {
      const { puzzle } = state;

      state.puzzle = puzzle;

      state.letterPresses = [
        ...state.letterPresses.filter((p) => p.gameIndex !== puzzle?.gameIndex),
      ];

      state.justFinished = false;
    },
    clearLetterPressesForLearningGames: (state) => {
      state.letterPresses = state.letterPresses.filter(
        (lp) => lp.gameIndex !== undefined && lp.gameIndex <= 100000
      );
    },
    clearPuzzle: (state) => {
      state.puzzle = {
        date: "",
        gameHash: "",
        gameIndex: 0,
        puzzleId: "",
        theme: "",
        words: [],
      };
    },
    loadPersistedGameState: (
      state,
      _action: PayloadAction<{ gameIndex?: number }>
    ) => {
      console.log("[hangfive] Loading persisted game state", _action);

      state.isLoading = true;
    },
    loadPersistedGameStateComplete: (
      state,
      { payload }: PayloadAction<{ gameIndex: number; gameState: GameState }>
    ) => {
      const { gameIndex, gameState } = payload;

      console.log("[hangfive] Loaded persisted game state for game", {
        payload,
        state: JSON.stringify(state),
      });

      state.letterPresses = gameState.letterPresses;
      state.isLoading = gameIndex !== state.puzzle.gameIndex;
      state.startTimestamp = gameState.startTimestamp;
      state.startTimestampGameIndex = gameIndex;
      state.stats = gameState.stats;
      state.hasHydrated = true;

      console.log("[hangfive] New state after loading persisted game state", {
        state: JSON.parse(JSON.stringify(state)),
      });

      console.log(
        "[hangfive] New startTimestamp after loaded persisted game state",
        { startTimestamp: state.startTimestamp }
      );

      // return state;
    },

    loadPuzzle: (
      state,
      action: PayloadAction<PuzzlePayload | LearningPuzzlePayload>
    ) => {
      const { theme, words, learning } = action.payload;

      const { puzzleId, date, gameIndex, link, gameHash, archive } =
        action.payload as PuzzlePayload;

      // TODO: Switch this to puzzleId in a few days
      if (state.puzzle.gameIndex !== gameIndex) {
        console.log("[hangfive] loadPuzzle: game index changed", {
          gameIndex,
          prevGameIndex: state.puzzle.gameIndex,
        });
        if (state.startTimestampGameIndex !== gameIndex) {
          state.startTimestamp = undefined;
        }
      } else {
        console.log("[hangfive] loadPuzzle: game index did not change", {
          gameIndex,
          prevGameIndex: state.puzzle.gameIndex,
        });
      }

      state.puzzle = {
        date,
        gameHash,
        gameIndex,
        link,
        puzzleId,
        theme,
        words,
      };

      if (!learning && !archive) {
        // clear letter presses for everything except the last week's
        state.letterPresses = state.letterPresses.filter(
          (lp) => lp.gameIndex && lp.gameIndex >= gameIndex - 7
        );
      }

      state.isArchive = archive ?? false;
      state.isLearning = learning ?? false;
      state.isLoading = false;
    },
    makeGuess: (state, action: PayloadAction<MakeGuessPayload>) => {
      const { letter: guessedLetter } = action.payload;

      const letterPresses = getLetterPressesForGameIndex(
        state.letterPresses,
        state.puzzle.gameIndex
      );

      const guessedLetters = letterPresses.map((lp) => lp.letter);

      if (guessedLetters.includes(guessedLetter)) {
        return;
      }

      const puzzleLetters = getLettersInWords(state.puzzle.words);

      const lostLifeReasons = getLostLifeReasons(puzzleLetters, letterPresses);

      const livesLeftBeforeGuess = maxLives - lostLifeReasons.length;
      const correctGuesses = getCorrectGuesses(letterPresses, puzzleLetters);

      const guessIsVowel = isVowel(guessedLetter);
      const onlyVowelsRemaining = getOnlyVowelsLeft(
        puzzleLetters,
        correctGuesses
      );

      const isGameEndingVowel =
        !onlyVowelsRemaining && guessIsVowel && livesLeftBeforeGuess === 1;

      const preventVowelFromBeingChosen = isGameEndingVowel; // TODO: An option for users to turn off early vowels

      if (preventVowelFromBeingChosen) {
        return;
      }

      const gameIndex = state.puzzle.gameIndex;

      const timestamp = new Date().getTime();

      // Do both for now, as need local var later on
      state.letterPresses.push({ gameIndex, letter: guessedLetter, timestamp });
      letterPresses.push({ gameIndex, letter: guessedLetter, timestamp });
    },

    persistCurrentGameState: () => {},

    setHasLoadingError: (state, action: PayloadAction<boolean>) => {
      state.hasLoadingError = action.payload;
    },

    setJustFinished: (state, action: PayloadAction<boolean>) => {
      state.justFinished = action.payload;
    },

    setLearningEndText: (state, action: PayloadAction<string>) => {
      state.learningEndText = action.payload;
    },

    setStats: (state, action: PayloadAction<UserStats>) => {
      state.stats = action.payload;
    },

    startLoading: (state) => {
      state.isLoading = true;
    },

    startPlaying: (state) => {
      if (!state.startTimestamp) {
        state.startTimestamp = new Date().getTime();
      }
    },
  },
});

export const selectGame = (state: RootState) => state.game;
export const selectStats = createSelector(selectGame, ({ stats }) => stats);

// DONE
export const selectPuzzle = createSelector(selectGame, ({ puzzle }) => puzzle);

export const selectPuzzleLetters = createSelector(selectPuzzle, ({ words }) =>
  getLettersInWords(words)
);

// DONE
export const selectCanShowSanta = createSelector(selectGame, ({ puzzle }) => {
  if (puzzle?.date) {
    return puzzle.date.endsWith(santaSuffix);
  }
  return false;
});

// DONE
export const selectJustFinished = createSelector(
  selectGame,
  ({ justFinished }) => justFinished
);

// DONE
export const selectIsArchive = createSelector(
  selectGame,
  ({ isArchive }) => isArchive
);

// DONE
export const selectIsLearning = createSelector(
  selectGame,
  ({ isLearning }) => isLearning
);

// DONE
export const selectIsLoading = createSelector(
  selectGame,
  ({ isLoading }) => isLoading
);

// DONE
export const selectHasLoadingError = createSelector(
  selectGame,
  ({ hasLoadingError }) => hasLoadingError
);

// DONE
export const selectLearningEndText = createSelector(
  selectGame,
  ({ learningEndText }) => learningEndText
);

type TrackSharePayload = { gameNumber: number; shareMethod: string };

export const postProgress = createAction("game/postProgress");
export const trackShare = createAction<TrackSharePayload>("game/trackShare");

// DONE
export const selectDate = createSelector(selectPuzzle, ({ date }) => date);

// DONE
export const selectGameIndex = createSelector(
  selectPuzzle,
  (puzzle) => puzzle.gameIndex
);

export const selectLetterPresses = createSelector(
  [selectGame, selectGameIndex],
  ({ letterPresses, isLoading }, gameIndex) =>
    isLoading ? [] : getLetterPressesForGameIndex(letterPresses, gameIndex)
);

export const selectGameStartTime = createSelector(
  [selectGame, selectGameIndex],
  ({ letterPresses, startTimestamp }, gameIndex) => {
    if (startTimestamp) {
      return startTimestamp;
    }

    const matchingTimestamps = letterPresses
      .filter((lp) => lp.letter === "" && lp.gameIndex === gameIndex)
      .map((lp) => lp.timestamp);

    if (matchingTimestamps && matchingTimestamps.length) {
      return matchingTimestamps[0];
    }

    return undefined;
  }
);

export const selectTimeTaken = createSelector(
  [selectLetterPresses, selectGameStartTime],
  (letterPress, gameStartTime) => {
    if (gameStartTime) {
      const last = letterPress[letterPress.length - 1];

      console.log("[hangfive] selectTimeTaken", {
        gameStartTime,
        last: last.timestamp,
        now: new Date().getTime(),
      });

      return last.timestamp - gameStartTime;
    }
    return undefined;
  }
);

export const selectCorrectGuesses = createSelector(
  [selectLetterPresses, selectPuzzleLetters],
  (letterPresses, validLetters) =>
    getCorrectGuesses(letterPresses, validLetters)
);

export const selectIncorrectGuesses = createSelector(
  [selectLetterPresses, selectPuzzleLetters],
  (letterPresses, validLetters) => {
    const incorrectGuesses = letterPresses.reduce(
      (incorrectGuesses, { letter }) => {
        if (!validLetters.includes(letter)) {
          incorrectGuesses.push(letter);
        }

        return incorrectGuesses;
      },
      [] as string[]
    );

    return incorrectGuesses;
  }
);

// DONE
export const selectLostLifeReasons = createSelector(
  [selectLetterPresses, selectPuzzleLetters, selectIsLoading],
  (letterPresses, puzzleLetters, isLoading) =>
    isLoading ? [] : getLostLifeReasons(puzzleLetters, letterPresses)
);

export const selectLivesLeft = createSelector(
  [selectLetterPresses, selectPuzzleLetters, selectGameIndex],
  (letterPresses, puzzleLetters, gameIndex) =>
    getLivesLeft(puzzleLetters, letterPresses, gameIndex)
);

export const selectGameStatus = createSelector(
  [
    selectLetterPresses,
    selectPuzzleLetters,
    selectCorrectGuesses,
    selectGameIndex,
    selectIsLearning,
  ],
  (letterPresses, validLetters, correctGuesses, gameIndex, isLearning) =>
    getGameStatus(
      letterPresses,
      validLetters,
      correctGuesses,
      gameIndex,
      isLearning
    )
);

export const selectShowSanta = createSelector(
  [selectCanShowSanta, selectGameStatus],
  (canShowSanta, gameStatus) =>
    canShowSanta && gameStatus !== GameStatus.IN_PROGRESS
);

export const selectScore = createSelector(
  [selectCorrectGuesses, selectPuzzleLetters, selectIsLoading],
  (correctGuesses, validLetters, isLoading) =>
    isLoading || validLetters.length === 0
      ? null
      : Math.floor((correctGuesses.length / validLetters.length) * 100)
);

export const selectGuessedLetters = createSelector(
  [selectLetterPresses],
  (letterPresses) => letterPresses.map((lp) => lp.letter).sort()
);

export const selectRemainingLetters = createSelector(
  [selectPuzzleLetters, selectCorrectGuesses],
  (puzzleLetters, correctGuesses) =>
    getRemainingLetters(puzzleLetters, correctGuesses).sort()
);

export const selectOnlyVowelsLeft = createSelector(
  [selectPuzzleLetters, selectCorrectGuesses],
  (puzzleLetters, correctGuesses) =>
    getOnlyVowelsLeft(puzzleLetters, correctGuesses)
);

export const selectDailyStats = createSelector(
  [selectStats],
  (stats) => stats?.daily
);

export const selectWinPercentage = createSelector(
  [selectDailyStats],
  (dailyStats) => {
    if (!dailyStats) {
      return 0;
    }

    if (dailyStats.playCount === 0) {
      return 0;
    }

    return ((dailyStats.winCount / dailyStats.playCount) * 100).toFixed(0);
  }
);

export const selectHasHydrated = createSelector(
  selectGame,
  ({ hasHydrated }) => hasHydrated
);

export const {
  clearGame,
  clearLetterPressesForLearningGames,
  clearPuzzle,
  loadPersistedGameState,
  loadPersistedGameStateComplete,
  loadPuzzle,
  makeGuess,
  persistCurrentGameState,
  setHasLoadingError,
  setJustFinished,
  setLearningEndText,
  setStats,
  startLoading,
  startPlaying,
} = gameSlice.actions;
export default gameSlice.reducer;
export type { GameState, MigratedGame };
